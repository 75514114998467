<script>
import request from '@/libs/request'
import {
  BCard, BFormGroup, BFormInput, BCardBody, BForm, BButton, BRow, BCol
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BCardBody,
    BForm,
    BButton,
    BRow,
    BCol
  },
  data () {
    return {
      meterData: {
        serie: '',
        cardSerie: '',
        meterNumber: '',
        firmware: '',
        hardware: ''
      },
      userDivision: this.$store.state.session.AppActiveUser().division
    }
  },
  methods: {
    onSubmit () {
      request({
        url: 'meters079',
        method: 'POST',
        params: { ...this.meterData, divisionUuid: this.userDivision.uuid },
        customMessages: true
      })
        .then(() => this.$router.push('/meters/GWH0079/'))
        .catch((err) => {
          const textError = err?.response?.data?.error || 'Error al registrar el medidor'
          this.$swal({
            title: 'Error',
            text: textError,
            icon: 'error',
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          })
        })
    },
    textTransform (val, name) {
      this.meterData[name] = val.toUpperCase()
    }
  }
}
</script>
<template>
    <div>
        <b-card title="Registrar Nuevo Medidor">
            <b-card-body>
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group label="Número de Serie Spore *">
                        <b-form-input maxlength="30" required v-model="meterData.serie" @update="textTransform($event, 'serie')"/>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group label="Número de Serie Tarjeta *">
                        <b-form-input maxlength="40" required v-model="meterData.cardSerie" @update="textTransform($event, 'cardSerie')"/>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group label="Número de Medidor *">
                        <b-form-input maxlength="30" required v-model="meterData.meterNumber" @update="textTransform($event, 'meterNumber')"/>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group label="Versión Firmware">
                        <b-form-input maxlength="10" v-model="meterData.firmware"/>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group label="Versión Hardware">
                        <b-form-input maxlength="10" v-model="meterData.hardware"/>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col>
                    <b-button variant="primary" type="submit">Registrar</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
        </b-card>
    </div>
</template>
